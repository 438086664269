import imagesloaded from "imagesloaded";
import AOS from "aos";
import Splitting from "splitting";

///////////////////////////////
// Initialize
///////////////////////////////

// Imagesloaded
window.imagesLoaded = imagesloaded;

// AOS
AOS.init({
  duration: 800
});

// Splitting
Splitting();


///////////////////////////////
// Functions
///////////////////////////////

//Headroom
var navbarWrapper = document.querySelector('.headroom');
var navbar = document.querySelector('.navbar');

var headroom = new Headroom(navbarWrapper, {
    tolerance: {
        up: 2,
        down: 2,
    },
});
headroom.init();


//Nav Icon
$('#nav-icon').click(function(){
    $(this).toggleClass('open');
});



// Page Section Background Image Preloader
imagesloaded(".page-section__background", { background: true }, function() {
  const backgrounds = document.querySelectorAll(".page-section__background");
  backgrounds.forEach(item => {
    item.classList.add("page-section__background--loaded");
  });
});

// Fade out page header background
const header = document.querySelector(".page-header .page-section__background");
if (header) {
  window.addEventListener("scroll", function(e) {
    const offset = $(window).scrollTop();
    if (offset <= header.clientHeight) {
      header.classList.add("scrolling");
      header.style.opacity = 1 - offset / header.clientHeight;
    } else {
      header.classList.remove("scrolling");
    }
  });
}

// Page section background color change
// This script changes the body background color based on data-section. It's currently turned off on page-builder.scss.
$(window).scroll(function() {

  // selectors
  var $window = $(window),
    $body = $('body'),
    $panel = $('section, header');

  // Change 33% earlier than scroll position so colour is there when you arrive.
  var scroll = $window.scrollTop() + ($window.height() / 2);

  $panel.each(function () {
    var $this = $(this);

    // if position is within range of this panel.
    // So position of (position of top of div <= scroll position) && (position of bottom of div > scroll position).
    // Remember we set the scroll to 33% earlier in scroll var.
    if ($this.position().top <= scroll && $this.position().top + $this.height() > scroll) {

      // Remove all classes on body with color-
      $body.removeClass(function (index, css) {
        return (css.match (/(^|\s)fade-\S+/g) || []).join(' ');
      });

      // Add class of currently active div
      $body.addClass('fade-' + $(this).data('section'));
    }
  });
}).scroll();


//Object Fit IE Fix
if ( window && window.Modernizr && !Modernizr.objectfit ) {
  $('.image-container').each(function () {
    var $container = $(this),
      imgUrl = $container.find('img').prop('src');
    if (imgUrl) {
      $container
        .css('backgroundImage', 'url(' + imgUrl + ')')
        .addClass('compat-object-fit');
    }
  });
}

//Text Carousel
var TxtRotate = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 1000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
};

TxtRotate.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];

    if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
    }

    this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

    var that = this;
    var delta = 170 - Math.random() * 100;

    if (this.isDeleting) { delta /= 2; }

    if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
    }

    setTimeout(function() {
        that.tick();
    }, delta);
};

window.onload = function() {
    var elements = document.getElementsByClassName('txt-rotate');
    for (var i=0; i<elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-rotate');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
            new TxtRotate(elements[i], JSON.parse(toRotate), period);
        }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.04em solid #666 }";
    document.body.appendChild(css);
};


//number counter on scroll
jQuery(window).scroll(startCounter);
function startCounter() {
    var hT = jQuery(".counter").offset() && jQuery(".counter").offset().top,
        hH = jQuery(".counter").outerHeight(),
        wH = jQuery(window).height();
    if (jQuery(window).scrollTop() > hT + hH - wH) {
        jQuery(window).off("scroll", startCounter);
        jQuery(".count").each(function() {
            var $this = jQuery(this);
            jQuery({ Counter: 0 }).animate(
                { Counter: $this.text() },
                {
                    duration: 4000,
                    easing: "swing",
                    step: function() {
                        $this.text(Math.ceil(this.Counter));
                    },
                }
            );
        });
    }
}


// Local Storage Keep Values of Checkboxes in Forms
var arr = JSON.parse(localStorage.getItem('checked')) || [];
arr.forEach((c, i) => $('.check-item').eq(i).prop('checked', c));

$('.check-item').click(() => {
    var arr = $('.check-item')
        .map((i, el) => el.checked)
        .get();
    localStorage.setItem('checked', JSON.stringify(arr));
});

document.getElementById('clear-checked-item');

$('#clear-checked-item').click(function () {
    localStorage.removeItem('checked');
});
